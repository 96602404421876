<template>
  <h2 class="mt-5">{{ quarterName(form.company, form.timeline) }} Performance Evaluation</h2>
  <div class="row mt-2" v-for="field in formFields" :key="field.label">
    <div class="col-5 mt-2 text-end">{{ field.label }}</div>
    <div class="col-4">
      <select v-if="field.name==='timeline'" class="form-select" v-model="updates[field.name]">
        <option value="Probation">Probation</option>
        <option v-for="timeline in FORM_TIMELINES" :key="timeline" :value="timeline">
          {{ timeline }}
        </option>
      </select>
      <select v-else-if="field.name==='company'" class="form-select" v-model="updates[field.name]">
        <option v-for="company in companyList" :key="company" :value="company">
          {{ company }}
        </option>
      </select>
      <select v-else-if="field.name==='template_name'" class="form-select" v-model="updates[field.name]">
        <option v-for="template in templates" :key="template.name" :value="template.name">
          {{ template.name }}
        </option>
      </select>
      <select v-else-if="field.name==='status'" class="form-select" v-model="updates[field.name]">
        <option v-for="status in statusList" :key="status" :value="status">
          {{ status }}
        </option>
      </select>
      <select v-else-if="field.name==='probation_period'" class="form-select" v-model="probationPeriod">
        <option v-for="days in probationPeriods" :key="days" :value="days">{{ days }} days</option>
      </select>
      <input v-else class="form-control" type="text" v-model="updates[field.name]" :placeholder="field.placeholder">
    </div>
  </div>
  <div class="text-center mt-3 mb-5">
    <button class="btn btn-primary" @click="save">{{ saveBtnText }}</button>
    &nbsp;<button type="button" class="btn btn-outline-dark" @click="cancel">Back</button>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { BACKEND } from '@/api';
import { FORM_TIMELINES } from '@/config/quarter';
import { quarterName } from '@/utils/quarter';

const FORM_FIELDS = [
  { label: 'Timeline', name: 'timeline' },
  { label: 'Company', name: 'company' },
  { label: 'BU Director Name', name: 'approver_name' },
  { label: 'BU Director Email', name: 'approver_email' },
  { label: 'Performance Manager Name', name: 'evaluator_name' },
  { label: 'Performance Manager Email', name: 'evaluator_email' },
  { label: 'Performance Manager Position', name: 'evaluator_position' },
  { label: 'Performance Manager BU', name: 'evaluator_bu', optional: true },
  { label: 'Employee ID', name: 'evaluatee_emp_id' },
  { label: 'Employee Name', name: 'evaluatee_name' },
  { label: 'Employee Email', name: 'evaluatee_email' },
  { label: 'Employee Position', name: 'evaluatee_position' },
  { label: 'Employee Level', name: 'evaluatee_level' },
  { label: 'Employee BU', name: 'evaluatee_bu', optional: true },
  { label: 'Project', name: 'project', optional: true },
  { label: 'Template', name: 'template_name' },
  { label: 'Status', name: 'status' },
  { label: 'Probation Period', name: 'probation_period', probation: true, optional: true },
  { label: 'Probation Start Date', name: 'probation_start_date', probation: true, placeholder: 'YYYY-MM-DD' },
  { label: 'Probation End Date', name: 'probation_end_date', probation: true, placeholder: 'YYYY-MM-DD' },
];

export default {
  name: 'FormListEdit',
  data() {
    return {
      FORM_TIMELINES: Object.freeze(FORM_TIMELINES),
      isNewForm: false,
      templates: {},
      form: {},
      updates: {},
      probationPeriod: 0,
    };
  },
  computed: {
    formFields() {
      return FORM_FIELDS.filter(field => !field.probation || this.updates.timeline === 'Probation');
    },
    companyList() {
      return this.$store.getters.groups?.split(',')
        .filter(x => x.startsWith('Admin_'))
        .map(x => x.split('_').slice(1).join(' ')).sort();
    },
    statusList() {
      const statusList = ["Disabled", "Pending"];
      if (this.form.submitted) {
        statusList.push("Submitted");
      }
      if (this.form.calibrated) {
        statusList.push("Calibrated");
      }
      if (this.form.approved) {
        statusList.push("Approved");
      }
      if (this.form.acknowledged_timestamp) {
        statusList.push("Completed");
      }
      return statusList;
    },
    probationPeriods() {
      if (this.updates.company == 'Bluebik Vietnam') {
        return [60, 180];
      } else if (this.updates.company == 'Bluebik TC') {
        return [90, 180];
      } else {
        return [119, 180];
      }
    },
    probationStartDate() {
      return this.updates.probation_start_date;
    },
    probationEndDate() {
      return this.updates.probation_end_date;
    },
    saveBtnText() {
      return this.isNewForm ? "Add Form" : "Save Changes";
    },
  },
  watch: {
    probationPeriod() {
      this.updateProbationEndDate();
    },
    probationStartDate() {
      this.updateProbationEndDate();
    },
    probationEndDate() {
      this.updateProbationPeriod();
    },
  },
  methods: {
    quarterName,
    updateProbationPeriod() {
      const startDate = moment(this.updates.probation_start_date, 'YYYY-MM-DD', true);
      const endDate = moment(this.updates.probation_end_date, 'YYYY-MM-DD', true);
      if (startDate.isValid() && endDate.isValid()) {
        this.probationPeriod = endDate.diff(startDate, 'days');
      }
    },
    updateProbationEndDate() {
      const startDate = moment(this.updates.probation_start_date, 'YYYY-MM-DD', true);
      if (startDate.isValid() && this.probationPeriod > 0) {
        const endDateStr = startDate.add(this.probationPeriod, 'days').format('YYYY-MM-DD');
        this.updates.probation_end_date = endDateStr;
      }
    },
    save() {
      for (const field of FORM_FIELDS) {
        if (field.probation && this.updates.timeline !== 'Probation') { continue; }
        if (!field.optional && !this.updates[field.name]) {
          alert(`${field.label} must be filled`);
          return;
        }
      }
      const url = this.isNewForm ? '/forms' : `/forms/${this.$route.params.formId}/update`;
      axios.post(`${BACKEND}${url}`, this.updates, {
        headers: {
          'Authorization': this.$store.getters.idToken
        }
      }).then((response) => {
        if (response.status === 200) {
          this.$router.push(`/forms/all?timeline=${this.updates.timeline}&status=${this.updates.status}`);
        }
      }).catch((error) => {
        console.log(error.response);
        alert(error.response.data.message);
      });
    },
    cancel() {
      this.$router.back();
    }
  },
  created() {
    axios.get(`${BACKEND}/templates`, {
      headers: {
        'Authorization': this.$store.getters.idToken
      }
    }).then((response) => {
      if (response.status === 200) {
        this.templates = response.data;
        this.templates.sort((a, b) => (a.name > b.name) ? 1 : -1);
      }
    });

    if (!this.$route.params.formId) {
      this.isNewForm = true;
      this.updates.timeline = FORM_TIMELINES[0];
      this.updates.status = 'Pending';
      return;
    }

    axios.get(`${BACKEND}/forms/${this.$route.params.formId}`, {
      headers: {
        'Authorization': this.$store.getters.idToken
      }
    }).then((response) => {
      if (response.status === 200) {
        this.form = response.data.form;
        for (const field of FORM_FIELDS) {
          if (this.form[field.name]) {
            this.updates[field.name] = this.form[field.name];
          }
        }
        this.updateProbationPeriod();
      }
    }).catch((error) => {
      console.log(error.response);
      this.$store.commit('logout');
    });
  }
}
</script>
